::selection {
  background: black;
  color: white;
}
::-moz-selection {
  background: black;
  color: white;
}

iframe {
  /* padding: 20px; */
  background: black;
  margin: 10px;
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

/* 
* {
  transition: 0.3s;
} */
html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

#footer {
  margin-top: 20px;
  background: black;
  color: gray;
  position: fixed;
  width: 100%;
  bottom: 0;
  /* margin-top: 60px; */
  text-align: left;
}

#footer p {
  padding: 5px 0px 5px 20px;
  margin: 0;
  font-size: 12px;
}
/* 
.fade {
  width: 100%;
  height: 100vh;
  background-color: black;
} */

a:visited {
  color: inherit;
}
.navbar a {
  padding: 8px;
  margin: 10px;
  transition: 0.1s;
  color: rgb(53, 53, 53);
}

.navbar a:hover {
  color: black;
}

.navbar a:focus {
  font-weight: 600;
  color: black;
}

.navbar a:active {
  font-weight: 600;
  color: black;
}

.navbar {
  padding: 22px 0 12px 10px;
  text-align: left;
  vertical-align: baseline;
  position: fixed;
  top: 0;
  border-bottom: 1px solid rgb(237, 237, 237);
  width: 100%;
  z-index: 500;
  background-color: rgb(255, 255, 255);
}

.navbar h1 {
  font-size: 20px;
  margin: 0;
  display: inline-block;
  /* display: inline-flex; */
  justify-content: center;
  /* margin: auto 0; */
  text-align: center;
}

/* 388 */

.navbar h1:hover {
  cursor: crosshair;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition: width 0.5s, height 0.5s, opacity 0.5s 1s;
}

.bio-about {
  margin-top: 110px;
  padding: 5px 10%;
  line-height: 38px;
  text-align: left;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 20px;
}

.bio-about h1 {
  font-weight: 600;
  line-height: 50px;
  text-align: left;
  color: rgb(255, 255, 255);
  background: rgb(9, 9, 9);
  display: inline;
}

.bio-section,
.contact-section {
  text-align: left;
  padding: 10px 10% 40px 10%;
  line-height: 25px;
}

.bio-section li,
.contact-section li {
  list-style-type: none;
  margin-bottom: 12px;
  line-height: 22px;
}

.contact-section {
  /* display: flex;
  justify-content: space-between;
  width: 350px; */
  margin-bottom: 50px;
}
.contact-section li {
  /* margin-top: 60px; */
  text-decoration: underline;
  box-sizing: border-box;
  padding: 4px;
}
.contact-section li a:hover {
  background-color: black;
  color: white;
  padding: 4px;
}
.bio-section h3,
.contact-section h3 {
  font-weight: 600;
  line-height: 20px;
  color: black;
  text-transform: uppercase;
}

.link-underline {
  text-decoration: underline dotted;
  color: grey;
}

.image-page-header {
  background: black;
  /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(0, 0, 0)); */
  padding: 1px 2% 1px 20px;
  position: fixed;
  top: 60px;
  /* right: 0; */
  left: 0;
  min-width: 25%;
  z-index: 700;
  display: flex;
  justify-content: center;
  align-content: center;
}

.image-page-header span {
  width: 10px;
  height: 10px;
  padding: 7px;
  cursor: pointer;
}

.image-page-header .previous {
  font-weight: 200;
}

.image-page-header h1 {
  vertical-align: center;
  font-size: 15px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  text-shadow: 1px 1px 50px black;
}

h1 {
  font-family: "Rubik", Helvetica, sans-serif;
  text-align: center;
  font-weight: 300;
}

.bio-about h2 {
  text-align: justify;
  text-justify: inter-word;
  line-height: 180%;
  font-weight: 400;
  font-size: 22px;
  font-family: "Rubik", sans-serif;
}

.wav {
  font-weight: 300;
  margin-top: 102px;
  padding: 5px 10%;
  line-height: 38px;
  text-align: left;
  font-size: 18px;
  margin-bottom: 20px;
}

.words {
  margin-top: 102px;
  padding: 5px 10%;
  line-height: 38px;
  text-align: left;
  font-weight: 300;
  font-size: 18px;
  margin-bottom: 20px;
}

.image-slider {
  margin-top: 90px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 550px;
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  opacity: 100;
  transition: 0.3s;
}

.image-slider::-webkit-scrollbar {
  display: none;
}

#slider-raw {
  display: flex;
}

.project {
  /* background: rgb(189, 78, 78); */
  display: inline-block;
  text-align: left;
  align-content: center;
  transition: transform 0.15s ease-in;
  /* text-transform: uppercase; */
  line-height: 20px;
  font-weight: 500;
}

.project-title {
  margin-left: 20px;
  font-weight: 600;
  color: black;
  cursor: crosshair;
}

.project-category {
  margin-left: 20px;
  padding: 1px;
  color: black;
  font-size: 11px;
  width: 75%;
  line-height: 16px;
}

.project:hover .project-category {
  display: inline-block;
  width: auto;
  max-width: 35ch;
  margin-top: 8px;
  padding: 8px 6px 6px 6px;
  background-color: black;
  border-radius: 7px;
  color: white;
}

.project:hover {
  z-index: 50;
  transform: translate(0, -50px);
  transform: scale(1.15);
}

.project:hover .project-title {
  color: black;
  font-size: 29px;
  line-height: 32px;
  font-weight: 600;
  cursor: crosshair;
  text-shadow: 2px 2px 12px rgb(206, 206, 206);
}

.project:hover .project-image img {
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.358);
  border: 8px solid rgb(255, 255, 255);
  cursor: crosshair;
}

.project-image img {
  box-sizing: border-box;
  width: 271px;
  height: 338px;
  object-fit: cover;
  display: inline-block;
  cursor: crosshair;
}

.src iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.project-image {
  /* background: rgb(162, 133, 133); */
  width: 300px;
  margin: 0 auto;
  margin-top: 50px;
  padding: 20px;
  display: inline-block;
  cursor: crosshair;
}

.project-image img {
  box-shadow: 2px 2px 20px rgb(255, 255, 255);
  /* border: 1px solid black; */
}
.post {
  margin-top: 50px;
  display: flex;
  align-content: flex-start;
}

.post:hover {
  cursor: crosshair;
}

.post-entry {
  max-width: 450px;
  text-align: left;
  float: left;
  margin: 0 auto;
  cursor: crosshair;
  white-space: pre-line;
}

.post-title {
  text-align: left;
  vertical-align: top;
  line-height: 0;
}

.post:hover h2 {
  color: black;
  cursor: crosshair;
  text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.178);
  transition: 0.3s;
}

.post:hover p {
  margin-top: 20px;
  font-size: 21px;
  font-weight: 600;
  line-height: 38px;
  cursor: crosshair;
  text-shadow: 2px 0px 8px rgba(0, 0, 0, 0.187);
  transition: 0.3s;
  background: rgba(128, 128, 128, 0.12);
  box-shadow: 10px 8px 8px rgba(128, 128, 128, 0.534);
  padding: 16px 20px;
  border-radius: 2px;
}

.post:hover {
  cursor: crosshair;
}

.post-entry:hover {
  cursor: crosshair;
}
.toggle-nav {
  display: flex;
  /* width: 200px; */
  /* justify-content: center; */
  vertical-align: top;
  position: absolute;
  right: 10px;
  top: 61px;
  padding-left: 20px;
  z-index: 500;
  font-size: 12px;
}

.toggle-nav:hover {
  cursor: pointer;
}

.inactive {
  animation-delay: 5s;
  animation-timing-function: ease-in-out;
  animation: shake 1.05s cubic-bezier(0.36, 0.07, 0.19, 0.87) both;
  color: black;
  padding: 2px 5px;
  transition: 0.3s;
}

#fader {
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-image: linear-gradient(to bottom, black 100%, black 100%);
  opacity: 0;
  /* transition: 2s; */

  pointer-events: none;
}

.faderOut {
  animation: fadeInAndOut;
  animation-duration: 2s;
}

.active {
  color: black;
  font-weight: 600;
  padding: 2px 5px;
  transition: 0.3s;
}

.inactive:hover {
  color: black;
}

.image-and-lightbox-wrapper {
  display: flex;
  background-color: grey;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
}

.lightbox {
  /* position: fixed; */
  /* background-color: red; */
  width: 75%;
  height: 100vh;
  box-sizing: border-box;
  padding-bottom: 20px;

  /* width: 65%;
  box-sizing: border-box;
  /* flex: 75%; */
  background-color: rgba(0, 0, 0, 0.879);
  /* top: 0;
  left: 0; */
  /* z-index: 1000; */
  /* width: 50%; */
  /* height: 100%; */
  transition: width 0.5s;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
}

.vignette {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  box-shadow: 0 0 200px rgb(0, 0, 0) inset; */
}

.vignette-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
  box-shadow: 0 0 80px rgba(79, 79, 79, 0.536) inset;
}

#src-projects-slider {
  margin-top: -5px;
  height: 600px;
}

.lightbox-image {
  max-width: 90%;
  max-height: 65vh;
  box-sizing: border-box;
  border: 8px solid white;
  margin: 20% auto;
}
.image-page {
  position: absolute;
  top: 60px;
  width: 100%;
  object-fit: fill;
  display: flex;
  justify-content: center;
  padding: 0px;
  background: white;
  /* background: linear-gradient(
    to bottom,
    transparent 0%,
    rgb(19, 19, 19) 10%,
    rgb(19, 19, 19) 100%
  ); */
  color: white;
  z-index: 50;
}

.image-grid {
  /* margin-top: 150px; */
  position: relative;
  background-color: black;
  width: 25%;
  object-fit: cover;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-content: flex-start;
  overflow: scroll;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex-grow: 2;
  align-items: stretch;
}

.image-container {
  width: 1fr;
  height: 180px;
  flex: 1;
  background-color: black;
  flex-basis: auto;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: crosshair;
  transition: all 0.4s;
  opacity: 0.4;
  flex-basis: auto;
}

.image-container:hover {
  /* transform: translate(-500px, -20px); 
  transform: scale(120%); */
  z-index: 600;
  flex: 3fr;
  border: 1px solid rgba(255, 255, 255, 0.81);
  box-sizing: border-box;
  transition: 0.2s;
  /* height: auto; */
  /* object-fit: contain; */
  /* flex-basis: fill; */
}
.image-container:not(:hover) .image-grid {
  justify-content: stretch;
}

.image-container img:hover {
  opacity: 1;
  flex: 3fr;

  /* flex: auto; */
  /* transform: translate(-500px, -20px);
  transform: scale(75%); */
  z-index: 900;

  /* border: 2px solid white; */
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.45); */
}

#src-project-wrapper {
  box-sizing: border-box;
  margin-top: 59px;
  top: -50px;
  display: block;
  width: 100%;
  height: auto;
  background-color: white;
  /* background-color: black; */
}

.src-header-image-section {
  width: 100%;
  max-height: 15vw;
  /* flex: 2; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: black;
}

.src-header-card img {
  opacity: 0.5;
  min-height: 102%;
  min-width: 102%;
  flex-shrink: 0;
}

.src-description-section {
  min-height: 100px;
  /* width: 100%; */
  z-index: 100;
  display: inline-block;
  /* margin: 30px; */
  background: white;

  /* display: flex;
  justify-content: center;
  flex-direction: column; */

  /* /* position: relative; */
  left: 0;
  /* background: rgb(255, 255, 255); */
  margin: 0 auto;
  text-align: left;
  padding: 10px 40px 20px 40px;

  /* overflow: hidden; */

  /* border: 2px solid grey; */
}

.src-pic {
  max-width: 150px;
  /* width: 150px; */
  max-height: 10%;
  float: left;
  padding: 5px 20px 5px 0;
}
.src-page {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  margin: 110px 60px;
}
.src-links {
  list-style-type: none;
  margin-top: 20px;
  padding: 0;
  font-weight: 600;
  text-align: left;
  /* width: 500px; */
  font-size: 19px;
  padding-top: 20px;
}

.src-links .src-description {
  background-color: rgba(0, 0, 0, 0.057);
  box-sizing: border-box;
  padding: 16px 20px;
  font-weight: 500;
  font-size: 0.8rem;
  max-width: 550px;
  font-size: 13px;
  display: block;
}

.src-links {
  margin-top: 2px;
  font-size: 12px;
  float: right;
  /* font-size: 19px; */
}

.tech {
  float: right;
  margin-top: 2px;
  font-size: 12px;
}

.src-links a {
  padding: 2px;
  text-decoration: underline;
}

.src-links a:hover {
  background: black;
  color: white;
  text-decoration: underline;
  /* font-style: italic; */
  padding: 3px;
}

.src-links p {
  margin-top: 8px;
  font-weight: 200;
  line-height: 20px;
  font-size: 15px;
}

/* @media only screen and (min-width: 700px) {
  /* .src-header-card {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-content: center;
    box-sizing: border-box;
  } */

/* .src-description-section {
    width: 110%;
    min-height: 350px;
    max-height: 550px;
  }

  .src-header-image-section {
    max-height: 75vh;
  }
}  */

.src-description-section h1 {
  padding: 8px 10px;
  position: relative;
  top: -4px;
  left: -41px;
  background: black;
  width: auto;
  display: inline-block;
  color: white;
  text-align: left;
  font-weight: 600;
}

.src-description-section a {
  display: block;
  text-decoration: underline;
  color: grey;
}
.src-description-section .blurb {
  margin-top: -1px;
  line-height: 28px;
  text-align: left;
  max-width: 425px;
  font-size: 17px;
}
.src-gallery-wrapper {
  border-top: 1px solid rgb(0, 0, 0);

  /* margin-top: 40px; */
  /* padding: 20px; */
  /* position: relative; */
  /* top: -280px; */
  /* box-shadow: 0px -20px 30px rgba(0, 0, 0, 0.735)0, 0, 0); */
  background-image: linear-gradient(to top, rgb(38, 38, 38), black);
}

.src-gallery-video-container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  color: white;
  text-align: left;
  padding: 20px;
}

.src-gallery-video-container h1 {
  width: auto;
  /* background: red; */
  font-weight: 600;
  text-transform: uppercase;
  font-size: 25px;
  /* margin-left: 6px; */
  text-align: left;
  /* margin-bottom: 50px; */
}
.src-gallery-video-container img {
  max-width: 60%;
  /* max-height: 300px; */
  border: 8px solid white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.358);
  margin: 0 auto;
}

.src-gallery-images-wrapper {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  /* padding: 20px; */
}

.src-gallery-images-wrapper img {
  min-width: 200px;
  margin: 10px;
  border: 1px solid black;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-blackuced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media only screen and (max-width: 388px) {
  .navbar h1 {
  }

  .navbar a {
    padding: 8px;
    margin: 10px;
    transition: 0.1s;
    color: rgb(53, 53, 53);
  }

  .navbar {
    display: flex;
    padding: 0;
    /* padding: 10px 0 0px 10px; */
    vertical-align: baseline;
  }
}

/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

.project-gallery {
  background-color: black;
  top: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes fadeInAndOut {
  0% {
    opacity: 1;
    background-image: linear-gradient(to bottom, black 100%, black 100%);
  }

  35% {
    opacity: 1;
    background-image: linear-gradient(to bottom, black 100%, black 100%);
  }

  45% {
    opacity: 0.9;
    background-image: linear-gradient(to bottom, black 100%, black 100%);
  }
  80% {
    background-image: linear-gradient(to bottom, black 100%, transparent 10%);
  }

  100% {
    opacity: 0;
  }
} ;
